import React from "react"
import { Link } from 'gatsby'

import {CenterContainer} from "../basics"


import eye from '../../images/eye/eye.png'
import iris from '../../images/eye/iris.png'



import Gesicht from '../gesicht/gesicht'


function PanopticTestGradient(props) {
   return   <div style={{position: "relative", width: "100%", paddingTop: "5vw", paddingBottom: "5vw",  background: "radial-gradient(black, #77d2ff)" }} >
   <Link to="/holonoptic">
         <div className="eye_iris2" id="eye_iris"    style={{position: "relative", maxWidth:"500px",  width: "70%", top: "0"}}>
             <img src={eye} id="eye2" alt="" />
             <img src={iris} id="iris2" alt="" />
         </div>
   </Link>
</div>
}



function PanopticTestNoGradient(props) {
    return   <div style={{position: "relative", width: "20vw"}} >
          <div className="eye_iris2" id="eye_iris">
              <img src={eye} id="eye2" alt="" />
              <img src={iris} id="iris2" alt="" />
          </div>
 </div>
 }





function GesichtGradient(props) {
    return   <div style={{position: "relative", width: "100%", height: "35vw", background: "radial-gradient(#77d2ff, black)" }} >

        <CenterContainer>
        <div style={{top:"8vw", position: "absolute"}}>

                  <Gesicht />
                  </div>

        </CenterContainer>


 </div>
 }




export {PanopticTestGradient, PanopticTestNoGradient, GesichtGradient}