import React from 'react'
import { Link } from 'gatsby'
import '../footer/footer.css'

import './glazialcom.css'
import glazialcom from './glazial.module.css'
import { GlacierImage, GlazialLogoBlack, GlazialLogoWhite } from '../images'


// gesicht
import '../navigation.css'

import { CenterContainer } from '../basics';




const Layout = ({ children }) => (

      <>
      <div style={{background:"white"}} className={glazialcom.layout}>


<div style={{ position: "absolute",

                height: "100vh", 
                width: "100vw" ,
                overflow:"hidden",
                  }}>
                  <GlacierImage />
  </div>

  <div style={{ position: "absolute",

                backgroundSize: "cover",
                backgroundPosition: "center",

                height: "100vh", 
                width: "100vw" ,
                backgroundImage: "linear-gradient(180deg, transparent 0%, rgba(255,255,255,1)  )",

                  }}>
  </div>




        <header style={{background:"rgba(255,255,255,.8)", position: "relative"}}>



            <CenterContainer>
                <Link to="/"  activeClassName="navlink">
                    <div id="glazial_logo_black"> <GlazialLogoBlack/> </div>
                </Link>
            </CenterContainer>

            <nav>
            <ul  id="navigation" className="navigation" style={{color:"black", background:"rgba(0,0,0,0.8)", position: "relative", textAlign: "center"}}>
           
                <Link to="/"  activeClassName="navlink">HOME</Link>
                <Link to="/press" activeClassName="navlink">PRESS</Link>
                <Link to="/holonoptic"  activeClassName="navlink">holonoptic</Link>

            </ul>
            </nav>

        </header>




        <div className="page-wrap" style={{minHeight:"100vh", marginBottom: "-120px", marginTop: "2vw", position: "relative" }}>

          {children}
          </div>

          <div style={{height:"11vh"}}></div>
          <div style={{height:"10vh"}}></div>


          <footer class="" style={{width: "100%", bottom: "0", height: "120px", borderTop: "solid 1px #77d2ff", marginTop: "8vw", paddingBottom:"8vw"}}>
                    <CenterContainer>

                                <div style={{color: "#77d2ff", textAlign:"center"}}>

                                        <Link to="/">
                                            <div   alt="" className="glazial_logo_glow" style={{width: "200px", padding: "20px"}} >
                                                <GlazialLogoWhite />
                                            </div>
                                        </Link>

                                        <p/>info@glazial.com<p/>
                                            8005 Zürich
                                </div>

                    </CenterContainer>
          </footer>


          </div>
      </>
)



export default Layout
