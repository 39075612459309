import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */



export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1920 quality: 100) {
      ...GatsbyImageSharpFluid_noBase64
    }
  }
}
`;





function withImageData(WrappedComponent) {
  return props => (
    <StaticQuery
      query={graphql`
          query {

            GlacierImage: file(relativePath: { eq: "main/glacier.jpg" }) {
              ...fluidImage
            }
            GlazialLogoBlack: file(relativePath: { eq: "glazial_black.png" }) {
                ...fluidImage
              }
            GlazialLogoWhite: file(relativePath: { eq: "main/glazial_white.png" }) {
              ...fluidImage
            }



          }
      `}
      render={data => <WrappedComponent {...props} imageData={data} />}
    />
  );
}

const GlacierImage = withImageData(props => (
  <Img fluid={props.imageData.GlacierImage.childImageSharp.fluid} />
));
const GlazialLogoBlack = withImageData(props => (
  <Img fluid={props.imageData.GlazialLogoBlack.childImageSharp.fluid} />
));
const GlazialLogoWhite = withImageData(props => (
  <Img fluid={props.imageData.GlazialLogoWhite.childImageSharp.fluid} />
));



export { GlacierImage, GlazialLogoBlack, GlazialLogoWhite };
