import React from 'react'


import gesicht from '../../images/main/gesicht.svg'
import gesicht2 from '../../images/main/gesicht_blue.svg'


import './image_glitch.css'



export default () =>
<>


<div className = "gesicht_wrapper">
  <div className="example-three">
  <img  src={gesicht} alt=""  />
  <img src={gesicht2} alt=""  />
  <img src={gesicht} alt=""  />
  </div>
</div>


</>