import React from 'react'


import {Wrap, SiteTitle} from "../components/basics"

import NewsGET from "../components/apps/newsGETall"
import FeaturedNewsGET from "../components/apps/newsGETfeatured"




import GlazialLayout from "../components/glazialcom/glazial_layout"

import {PanopticTestGradient} from "../components/glazialcom/glazialComponents"



export default () =>
<>
<SiteTitle string="Glazial Group" />





<GlazialLayout>

<Wrap >




    <PanopticTestGradient />

    <h2 style={{textAlign:"center"}}>
          The GLAZIAL GROUP is looking for a new CEO. Take our holonoptic™ test to apply.
    </h2>

      <hr />
      <br/><br/>

    <h2>NEWS</h2>
              <FeaturedNewsGET />
              <hr></hr>
              <h5 style={{margin:"0"}}>older news</h5>
              <NewsGET />








</Wrap>

</GlazialLayout>

  

</>
