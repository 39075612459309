import React from 'react'


class FeaturedNewsFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
  this.fetch ();

  }


fetch ()  {     fetch("https://gesicht.pythonanywhere.com/featuredNEWS_GET", { method: 'get', mode: 'cors' })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      ) }


  componentDidUpdate(prevprops) {
    if (prevprops.needsupdate !== this.props.needsupdate) { this.fetch()}
  }


  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <>


          {items.reverse().map((item, index) => ( <div key={index}>
            <div className="newsItem" >
            <h6>{item.date}</h6>
            <p  style={{margin: "0"}}>
              {item.text}
            </p>
            <a href={item.url}>{item.button}</a>
            </div>
            </div>
          ))}

        </>
      );
    }
  }
}




export default FeaturedNewsFeed;